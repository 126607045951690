import React, { useState, useEffect } from 'react';
import { get } from 'aws-amplify/api';


const Week = () => {
  const [data, setData] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiName = 'api0e3ede1c';
        const path = '/api/week'
        const restOperation = get({
          apiName: apiName,
          path: path
        })
        const { body, response } = await restOperation.response;
        const jsonData = await body.json();
        if (Array.isArray(jsonData)) {
          setData(jsonData);
        } else {
          console.error('Data received is not an array:', jsonData);
          setData([]); 
        }
      } catch (error) {
        console.error('There was a problem with your fetch operation:', error.response.body);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {data ? (
        data.map((letters, index) => (
          <p key={index}>{letters.letter}</p> 
        ))
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default Week;
