import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import Modal from 'react-modal';
import '../Modal.css';
import '../Calendar.css';
import { get } from 'aws-amplify/api';
import '../Letters.css';
import jsPDF from 'jspdf';


Modal.setAppElement('#root');

const CalendarComponent = () => {
  const [letters, setLetters] = useState([]); 
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showModal, setShowModal] = useState(false);
  const [letterContent, setLetterContent] = useState('');
  const [selectedRecipientName, setSelectedRecipientName] = useState(''); 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLetter, setSelectedLetter] = useState(null);

  useEffect(() => {
    const fetchLetters = async () => {
      try {
        const apiName = 'api0e3ede1c';
        const path = '/api/calendar';
        const restOperation = get({
          apiName: apiName,
          path: path
        });
        const { body, response} = await restOperation.response;
        const jsonData = await body.json();
        setLetters(jsonData);
      } catch (error) {
        console.error("Failed to fetch letters:", error);
      }
    };

    fetchLetters();
  }, []);

  const formatDate = (date) => {
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
  };

  const tileClassName = ({ date, view }) => {
    const dateStr = formatDate(date);
    if (view === 'month' && letters.some(entry => formatDate(new Date(entry.recipient_date)) === dateStr)) {
      return 'green-tile';
    }
    if (view === 'month' && formatDate(date) === formatDate(new Date())) {
      return 'highlight-today';
    }
  };

  const closeLetterModal = () => {
    setSelectedLetter(null);
    setLetterContent(null);
    setIsModalOpen(false);
  };

  const printLetter = () => {
    if (!letterContent) return;
  
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
      putOnlyUsedFonts: true,
      compress: true,
      precision: 16 // Higher precision for measurements
    });

    doc.setFontSize(15);
    doc.setFont('helvetica');

  
    // Create a DOM parser to extract <p> tags content
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(letterContent, 'text/html');
    const paragraphs = htmlDoc.querySelectorAll('p');
  
    let fullText = ""; // Initialize an empty string to accumulate all paragraphs
    Array.from(paragraphs).forEach(p => {
      // For each paragraph, add an indent and a newline at the end
      fullText += "    " + p.textContent + "\n"; // Adding 4 spaces for indentation
    });
  
    // Set initial cursor position and page settings
    let cursorY = 30; // Initial vertical position for the text
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 30; // Margin from the sides
    const maxWidth = pageWidth - 2 * margin; // Calculate the maximum width of text
    const lineSpacing = 7; // Line spacing
    const bottomMargin = 260; // Hard limit for the bottom margin
  
    // Split the full text into lines that fit within the page width
    const lines = doc.splitTextToSize(fullText, maxWidth);
  
    // Add text line by line, ensuring no line is placed beyond the bottom margin
    lines.forEach(line => {
      if (cursorY > bottomMargin - lineSpacing) { // Check if placing the next line would exceed the hard bottom margin
        doc.addPage();
        cursorY = 30; // Reset cursor position for the new page
      }
      doc.text(line, margin, cursorY);
      cursorY += lineSpacing; // Increment cursor for the next line
    });
  
    doc.autoPrint(); // Save the PDF
    doc.output('dataurlnewwindow');
  };

  const handleDayClick = (value) => {
    const dateStr = formatDate(value);
    setSelectedDate(value);
    setShowModal(true);

    const lettersForDate = letters.filter(letter => formatDate(new Date(letter.recipient_date)) === dateStr);
    if (lettersForDate.length > 0) {
      setSelectedRecipientName(lettersForDate[0].recipient_name); 
      handleFetchLetterContent(lettersForDate[0].recipient_id);
    } else {
      setLetterContent("No letters found for this date.");
      setSelectedRecipientName(''); 
    }
  };

  const handleFetchLetterContent = async (recipientId) => {
    try {
        const apiName = 'api0e3ede1c';
        const path = `/api/content?recipient_id=${encodeURIComponent(recipientId)}`;
        const restOperation = get({
          apiName: apiName,
          path: path
        });
        const { body, response} = await restOperation.response;
        const jsonData = await body.json();

        if (jsonData.length === 0 || !jsonData[0].letter_content.trim()) {
            setLetterContent("No letter content");
        } else {
            setLetterContent(jsonData[0].letter_content); 
        }
    } catch (error) {
        console.error("Error fetching letter content:", error);
        setLetterContent("Failed to fetch letter content");
    }
  };

  const renderModalContent = () => {
    return (
      <div>
        <p className="date-heading"><strong>Date:</strong> {formatDate(selectedDate)}</p>
        {selectedRecipientName && <p><strong>Recipient Name:</strong> {selectedRecipientName}</p>}
        <h2 className="text-center text-3xl font-semibold mb-4 mt-8">Letter Content</h2>
        <div style={{ margin: '10px', padding: '15px', borderRadius: '5px' }}>
          <div dangerouslySetInnerHTML={{ __html: letterContent }} />
        </div>
      </div>
    );
  };

  const renderModal = () => {
    return (
      <>
        <div className="overlay-container" onClick={() => setShowModal(false)}></div>
        <div className="modal-content-container" style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1050,
          overflowY: 'auto', 
          maxHeight: '80%',
        }}>
          <h1 className="text-xl font-bold">Letters for {formatDate(selectedDate)}</h1>
          <div className="modal-content-wrapper">
          {renderModalContent()}
        </div>
        <div className="button-container">
          <button onClick={printLetter} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none">
            Print
          </button>
          <button onClick={() => setShowModal(false)} className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none">
            Close
          </button>
        </div>
        </div>
      </>
    );
  };

  return (
    <div className="relative w-full min-h-screen flex justify-center"> {/* Provide minimum height and full width */}
  {showModal && renderModal()}
  <Calendar
    className="absolute top-0 w-full"
    onChange={handleDayClick}
    value={selectedDate}
    tileClassName={tileClassName}
  />
</div>

  );
};

export default CalendarComponent;
