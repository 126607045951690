import React, { useState, useEffect, useRef, useCallback } from 'react';
import Modal from 'react-modal';
import '../Letters.css';
import _ from 'lodash';
import ReactToPrint from 'react-to-print';
import { useNavigate, Link } from 'react-router-dom';
import { get } from 'aws-amplify/api';

const handlePrint = () => {
  const contentNode = document.querySelector('.printable-content');
  const originalContent = contentNode.innerHTML;
  const words = originalContent.split(' ');
  const wordsPerBlock = 300;
  let paginatedContent = '';
  
  for (let i = 0; i < words.length; i += wordsPerBlock) {
    const pageContent = words.slice(i, i + wordsPerBlock).join(' ');
    paginatedContent += `<p>${pageContent}</p>`;
    if (i + wordsPerBlock < words.length) {
      paginatedContent += `<div class="page-break"></div>`;
    }
  }
  
  contentNode.innerHTML = paginatedContent;
  window.print(); 
  contentNode.innerHTML = originalContent; 
};  

const LetterContentToPrint = React.forwardRef(({ letterContent }, ref) => {
  return (
    <div ref={ref} className="printable-content">
      <p>{letterContent}</p>
    </div>
  );
});

const UsersComponent = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const componentRef = useRef();

  const debouncedSearch = useCallback(_.debounce((query) => {
    fetchUsers(query);
  }, 300), []);

  useEffect(() => {
    fetchUsers(searchTerm);
  }, [currentPage, itemsPerPage]);

  const fetchUsers = async (query) => {
    try {
      const apiName = 'api0e3ede1c';
      const path = `/api/users?page=${currentPage}&limit=${itemsPerPage}&search=${encodeURIComponent(query)}`;
      const restOperation = get({
        apiName: apiName,
        path: path
      });
      const { body, response} = await restOperation.response;
      const jsonData = await body.json();
      setUsers(jsonData.users);
    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    debouncedSearch(event.target.value);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <div className='mx-40 mt-10'>
      <h1 className="text-center text-3xl font-semibold mb-4">
        ForeverLetter Users
      </h1>
      <div className="flex mb-4">
        <input
          type="text"
          placeholder="Search by name or email"
          value={searchTerm}
          onChange={handleSearchChange}
          className="form-input mt-1 block w-1/4 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
        />
      </div>
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="py-3 px-6">Display Name</th>
            <th scope="col" className="py-3 px-6">Email</th>
            <th scope="col" className="py-3 px-6">Registration Date</th>
            {/* <th scope="col" className="py-3 px-6">Recipients</th> */}
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={user.ID} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} text-black hover:bg-blue-200 cursor-pointer`} onClick={() => navigate(`/recipient?userId=${user.ID}&displayName=${user.display_name}&userEmail=${user.user_email}`)}>
              <td className="py-4 px-6">{user.display_name}</td>
              <td className="py-4 px-6">{user.user_email}</td>
              <td className="py-4 px-6">{user.user_registered}</td>
              {/* <td>
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  <Link to={`/recipient?userId=${user.ID}&displayName=${user.display_name}`}>Recipients</Link>
                </button>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-center space-x-4 mt-4">
        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className="bg-gray-500 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out hover:bg-gray-700 disabled:bg-gray-200 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
          Previous
        </button>
        <button onClick={() => handlePageChange(currentPage + 1)} className="bg-blue-500 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
          Next
        </button>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Letter Content"
        className="modal-content"
        overlayClassName="overlay"
      >
        <h2 className="text-center text-3xl font-semibold block mb-8">Letter Content</h2>
        <LetterContentToPrint letterContent={selectedUser?.letterContent} ref={componentRef} />
        <div className="flex justify-end">
          <ReactToPrint
            trigger={() => <button onClick={handlePrint} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-10">Print Letter</button>}
            content={() => componentRef.current}
          />
          <button onClick={() => setIsModalOpen(false)} className="close">
            x
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default UsersComponent;
