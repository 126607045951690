import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './Dashboard.jsx';
import AllPage from './pages/All';
import TodayPage from './pages/Today';
import MonthPage from './pages/Month';
import WeekPage from './pages/Week';
import Home from './pages/Home';
import RecipientPage from './pages/Recipients';
import BookingPage from './pages/AllBookings';
import UsersPage from './pages/Users';
import LetterEditor from './pages/LetterEditor.jsx';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Editor } from '@tinymce/tinymce-react';


function App(props) {
  return (
    <Router>
      <Dashboard >
        <Routes>
          <Route path="/" element={<Home {...props} />} />
          <Route path="/all" element={<AllPage {...props} />} />
          <Route path="/today" element={<TodayPage {...props} />} />
          <Route path="/month" element={<MonthPage {...props} />} />
          <Route path="/week" element={<WeekPage {...props} />} />
          <Route path="/recipient" element={<RecipientPage {...props} />} />
          <Route path="/booking" element={<BookingPage {...props} />} />
          <Route path="/users" element={<UsersPage {...props} />} />
          <Route path="/users" element={<UsersPage {...props} />} />
          <Route path="/lettereditor" element={<LetterEditor {...props} />} />
          {/* Define other routes here */}
        </Routes>
      </Dashboard>
    </Router>
  );
}

export default withAuthenticator(App);