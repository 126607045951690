import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { get } from 'aws-amplify/api';
import { useNavigate, useLocation } from 'react-router-dom';
import "../LetterModal.css";
import jsPDF from 'jspdf';
import { Editor } from '@tinymce/tinymce-react';
import { BiFullscreen } from 'react-icons/bi';

const LetterEditor = () => {
  const editorContainer = document.getElementById('editorcontainer');
  const location = useLocation();

  const [selectedLetter, setSelectedLetter] = useState(null);
  const [letterContent, setLetterContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const editorRef = useRef(null);
  const [dirty, setDirty] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const recipient_id = searchParams.get('recipientid');

  useEffect(() => setDirty(false), [letterContent]);


  useEffect(() => {
    const fetchLetterContent = async () => {
    try{
      const apiName = 'api0e3ede1c';
      const path = `/api/content/?recipient_id=${recipient_id}`;

      const restOperation = get({
        apiName: apiName,
        path: path
      });
      const { body, response} = await restOperation.response;
      const jsonData = await body.json();
      setLetterContent(jsonData[0].letter_content); 
      console.log('letter set');
      } catch (error) {
        console.error("Failed to fetch letter content:", error);
        setLetterContent("No letter content available.");
      }
    }
    fetchLetterContent();
  }, [recipient_id]);

  const save = () => {
    if (editorRef.current) {
      const content = editorRef.current.getContent();
      setDirty(false);
      editorRef.current.setDirty(false);
      // an application would save the editor content to the server here
      console.log(content);
    }
  };
  
  return (
    <div>
      <Editor
        apiKey='z7g05av9k8z1nmrzliusqgjb7jg74v2hqm361zrv1n8wpkvs'
        initialValue={letterContent}
        onInit={(evt, editor) => {
          editorRef.current = editor;
          console.log("Editor has been initialized");
        }}
        onEditorChange={(newContent) => {
          setLetterContent(newContent);
        }}
        init={{
          content_style: `
          body {
            font-family: Arial;
            font-size: 10.5pt;
            line-height: 1.6;
          }
          @media print {
            @page {
              margin-top: 50mm;
              margin-bottom: 50mm;
              margin-left: 50mm;
              margin-right: 50mm;
            }
          }
        `,
          height: 1000,
          menubar: false,
          plugins: 'print',
          toolbar: 'print'
        }}
      />
      {dirty && <p>You have unsaved content!</p>}
    </div>
  );

};

export default LetterEditor;
