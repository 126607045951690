import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import '../Letters.css';
import ReactToPrint from 'react-to-print';
import { useNavigate } from 'react-router-dom';
import { get } from 'aws-amplify/api';
import { Link } from 'react-router-dom';

const handlePrint = () => {
    const contentNode = document.querySelector('.printable-content');
    const originalContent = contentNode.innerHTML;
    const words = originalContent.split(' ');
    const wordsPerBlock = 300;
    let paginatedContent = '';
    
    for (let i = 0; i < words.length; i += wordsPerBlock) {
      const pageContent = words.slice(i, i + wordsPerBlock).join(' ');
      paginatedContent += `<p>${pageContent}</p>`;
      if (i + wordsPerBlock < words.length) {
        paginatedContent += `<div class="page-break"></div>`;
      }
    }
    
    contentNode.innerHTML = paginatedContent; 
    window.print(); 
    contentNode.innerHTML = originalContent; 
  };  

  const LetterContentToPrint = React.forwardRef(({ letterContent }, ref) => {
    return (
      <div ref={ref} className="printable-content">
        <p>{letterContent}</p>
      </div>
    );
  });

  const LettersComponent = () => {
    const navigate = useNavigate();
    const [letters, setLetters] = useState([]);
    const [selectedLetter, setSelectedLetter] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const componentRef = useRef();
  
    useEffect(() => {
      const fetchLetters = async () => {
        try {
          const apiName = 'api0e3ede1c';
          const path = '/api/recipient';
          const restOperation = get({
            apiName: apiName,
            path: path
          });
          const { body, response} = await restOperation.response;
          const jsonData = await body.json();
          console.log("Fetched letter_recipient data:", jsonData);
          setLetters(jsonData);
        } catch (error) {
          console.error("Failed to fetch letters:", error);
        }
      };
  
      fetchLetters();
    }, []);

    const lettersWithRecipientNames = letters.filter(letter => {
      const recipientName = letter.recipient_name || '';
      return recipientName.trim() !== '';
    });

    console.log("Letters with recipient names:", lettersWithRecipientNames);

  return (
    <div className='mr-30 ml-30'>
      <h1 className="text-center text-3xl font-semibold block mt-10">
          All Bookings
      </h1>
      <table className="letters-table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Recipient Name</th>
            <th>Recipient Email</th>
            <th>Recipient Phone</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {lettersWithRecipientNames.map(letter => (
            <tr key={letter.booking_id}>
              <td>{letter.booking_id}</td>
              <td>{letter.recipient_name}</td>
              <td>{letter.recipient_email}</td>
              <td>{letter.recipient_phone}</td>
              <td>
                  <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    <Link to="/recipient">
                      Recipients
                    </Link>
                  </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Letter Content"
        className="modal-content"
        overlayClassName="overlay"
      >
        <h2 className="text-center text-3xl font-semibold block mb-8">Letter Content</h2>
        <LetterContentToPrint letterContent={selectedLetter?.letterContent} ref={componentRef} />
        <div className="flex justify-end">
          <ReactToPrint
            trigger={() => <button onClick={handlePrint} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-10">Print Letter</button>}
            content={() => componentRef.current}
          />
          <button onClick={() => setIsModalOpen(false)} className="close">
            x
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default LettersComponent;