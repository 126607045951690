import React from 'react';
import { useState, useEffect } from 'react';
import '../Letters.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { get } from 'aws-amplify/api';
import { IoArrowBack } from 'react-icons/io5';

const LettersComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [letters, setLetters] = useState([]);
  const [selectedLetter, setSelectedLetter] = useState(null);
  const [letterContent, setLetterContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get('userId');
  const displayName = searchParams.get('displayName');
  const userEmail = searchParams.get('userEmail');

  useEffect(() => {
    const body = document.body;
    if (isModalOpen) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'visible';
    }

    return () => {
      body.style.overflow = 'visible';
    };
  }, [isModalOpen]);

  useEffect(() => {
    const fetchLetters = async () => {
      try {
        
        const apiName = 'api0e3ede1c';
        const path = `/api/userletters/?user_id=${userId}`; 
        const restOperation = get({
          apiName: apiName,
          path: path
        });
        const { body, response} = await restOperation.response;
        const jsonData = await body.json();
        console.log("Fetched user letters:", jsonData);
        setLetters(jsonData);
      } catch (error) {
        console.error("Failed to fetch letters:", error);
      }
    };

    fetchLetters();
  }, [userId]);

  const handleRecipientClick = (letter) => {
    setSelectedLetter(letter);
    setIsModalOpen(true);
  };

  const lettersWithRecipientNames = letters.filter(letter => {
    const userName = letter.user_name || '';
    return userName.trim() !== '';
  });
  
  return (
    <div className='mx-40 my-10'>
      <div className="flex items-center justify-between">
        <button onClick={() => navigate('/users')} className="text-gray-700 hover:text-gray-900 flex items-center">
          <IoArrowBack className="mr-2" /> Back to Users
        </button>
        <h1 className="text-center text-3xl font-semibold flex-grow">
          {displayName}'s Recipients
        </h1>
        <div className="opacity-0">
          <IoArrowBack className="mr-2" /> Back to Users
        </div>
      </div>

      {/* User Info Section */}
      <div className="my-4">
        <h2 className="text-xl font-semibold">User Information</h2>
        <p><strong>Name:</strong> {displayName}</p>
        <p><strong>Email:</strong> {userEmail}</p>
        <p><strong>User ID:</strong> {userId}</p>
      </div>

      {/* Recipients List */}
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="py-3 px-6">Booking ID</th>
            <th scope="col" className="py-3 px-6">Recipient Name</th>
            <th scope="col" className="py-3 px-6">Recipient Email</th>
            <th scope="col" className="py-3 px-6">Recipient Address</th>
            <th scope="col" className="py-3 px-6">Recipient Phone</th>
            <th scope="col" className="py-3 px-6">Registration Date</th>
          </tr>
        </thead>
        <tbody>
          {letters.map((letter, index) => (
            <tr key={letter.recipient_id} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'} text-black hover:bg-blue-200 cursor-pointer`} onClick={() => navigate(`/lettereditor?recipientid=${letter.recipient_id}`)}>
              <td>{letter.booking_id}</td>
              <td>{letter.recipient_name}</td>
              <td>{letter.recipient_email}</td>
              <td>{letter.recipient_address}</td>
              <td>{letter.recipient_phone}</td>
              <td>{letter.register_recipient_date}</td>
              {/* <td>
                <button onClick={() => openLetterModal(letter)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  View Letter
                </button>
              </td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LettersComponent;
