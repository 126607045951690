import React from 'react';
import { Menu } from '@headlessui/react';
import { Link } from 'react-router-dom';
import SearchComponent from './SearchComponent';
import { useEffect, useState } from 'react';
import { getCurrentUser, signOut } from 'aws-amplify/auth';

const Dashboard = ({children}) => {
  const [username, setUsername] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { username } = await getCurrentUser();
        setUsername(username);
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    fetchUser();
  }, []);
  
  const handleLogout = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  };

  return (
    <div>
      <nav className="bg-gray-800 p-4">
        <div className="container mx-auto flex justify-between items-center">
          <div className="flex items-center">
            <Link to="/" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4">
              Home
            </Link>
            <Link to="/users" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4">
              Users
            </Link>
          </div>
          <div className='text-center'>
            <Link to="/" className="text-white text-3xl font-semibold">
              ForeverLetter Admin Dashboard
            </Link>
          </div>
          <div className="flex justify-end text-white">
            <Menu as="div" className="relative inline-block text-left">
              <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-md font-medium text-white bg-gray-800 rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
              onClick={() => setIsDropdownOpen((prev) => !prev)}>
                Hello, {username || 'User'}
                <svg
                  className={`-mr-1 ml-2 h-5 w-5 transition-transform ${isDropdownOpen ? 'rotate-180' : ''}`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </Menu.Button>
              <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button onClick={handleLogout} className={`${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'} group flex w-full items-center px-4 py-2 text-sm`}>
                        Logout
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Menu>
          </div>
        </div>
      </nav>
      {children}
    </div>
  );
};

export default Dashboard;


// <Menu as="div" className="relative mr-6"> {/* Added mr-4 for margin to the right */}
              // <Menu.Button className="inline-flex items-center w-full text-white">
                // Letters
                // <svg
                //   className="-mr-1 ml-2 h-5 w-5"
                //   xmlns="http://www.w3.org/2000/svg"
                //   fill="none"
                //   viewBox="0 0 24 24"
                //   stroke="currentColor"
                // >
              //     <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
              //   </svg>
              // </Menu.Button>
              // <Menu.Items className="absolute left-0 w-40 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              //   <div className="py-1">
              //     <Menu.Item>
              //       {({ active }) => (
              //         <Link
              //           to="/today"
              //           className={`${
              //             active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
              //           } group flex items-center px-4 py-2 text-sm`}
              //         >
              //           Today
              //         </Link>
              //       )}
              //     </Menu.Item>
              //     <Menu.Item>
              //       {({ active }) => (
              //         <Link
              //           to="/week"
                //         className={`${
                //           active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                //         } group flex items-center px-4 py-2 text-sm`}
                //       >
                //         Week
                //       </Link>
                //     )}
                //   </Menu.Item>
                //   <Menu.Item>
                //     {({ active }) => (
                //       <Link
                //         to="/month"
                //         className={`${
                //           active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                //         } group flex items-center px-4 py-2 text-sm`}
                //       >
                //         Month
                //       </Link>
                //     )}
                //   </Menu.Item>
                // </div>
            //   </Menu.Items>
            // </Menu>