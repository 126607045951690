import React from 'react';
import { useState, useEffect } from 'react';
import CalendarComponent from './Calendar';
import Today from './Today';
import Week from './Week';
import Month from './Month';
import All from './All';
import { get } from 'aws-amplify/api';
import { Link } from 'react-router-dom';

const Home = () => {
  const [todayData, setTodayData] = useState([]);
  const [weekData, setWeekData] = useState([]);
  const [monthData, setMonthData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [showToday, setShowToday] = useState(false);
  const [showWeek, setShowWeek] = useState(false);
  const [showMonth, setShowMonth] = useState(false);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    const fetchData = async (endpoint, setter) => {
      try {
        const apiName = 'api0e3ede1c';
        const path = '/api/' + endpoint
        const restOperation = get({
          apiName: apiName,
          path: path
        })
        const { body, response } = await restOperation.response;
        const jsonData = await body.json();
        setter(jsonData);
      } catch (error) {
        console.error(`There was a problem with fetching ${endpoint}:`, error);
      }
    };
      
      fetchData('today', setTodayData);
      fetchData('week', setWeekData);
      fetchData('month', setMonthData);
      fetchData('calendar', setAllData);
    }, []);

  const getWeekRange = () => {
    const today = new Date();
    const firstDay = new Date(today.setDate(today.getDate() - today.getDay()));
    const lastDay = new Date(today.setDate(today.getDate() - today.getDay() + 6));
    return firstDay.toLocaleDateString() + " - " + lastDay.toLocaleDateString();
  };

  const getMonthName = () => {
    const today = new Date();
    return today.toLocaleString('default', { month: 'long' });
  };

  const renderDataCard = (data, title, showData, setShowData) => (
    <div>
      <div className="font-bold mb-2 cursor-pointer" onClick={() => setShowData(!showData)}>
        <h2 className="inline">{title} <span style={{ marginLeft: '10px', color: 'grey' }}>{!showData ? '▲' : '▼'}</span></h2>
      </div>
      {!showData && (
        <ul>
          {data.filter(letter => letter.recipient_name && letter.recipient_email && letter.recipient_date).map((letters, index) => (
            <li key={index}>{letters.letter}
              <div style={{ marginTop: '20px' }}>Date: {letters.recipient_date}</div>
              <div>Name: {letters.recipient_name}</div>
              <div>Email: {letters.recipient_email}</div>
              <button onClick={() => {console.log('button clicked')}} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                View Letter
              </button>
            </li>
          ))}
          {data.filter(letter => letter.recipient_name && letter.recipient_email && letter.recipient_date).length === 0 && (
            <li>No letters {title.toLowerCase().includes("today") ? "for today" : title.toLowerCase().includes("this week") ? "for this week" : title.toLowerCase().includes("this month") ? "for this month" : ""}</li>
          )}
        </ul>
      )}
    </div>
  ); 

  return (
    <div className="mx-auto mt-8 flex">
      <div className="w-1/3 flex flex-col items-center justify-center">
        <h2 className="text-center font-bold mb-4">Review letters for a specific date:</h2>
        <CalendarComponent />
      </div>
      <div className="w-2/3 flex flex-col space-y-4 mr-20 mt-6">
        <div className="flex items-center">
            {/* <SearchComponent /> Include the SearchComponent here */}
        </div>
        {/* <div className="bg-white p-6 rounded shadow-lg">
          {renderDataCard(allData, "All Letters", showAll, setShowAll)}
          <All />
        </div> */}
        <div className="bg-white p-6 rounded shadow-lg">
          {renderDataCard(todayData, "Today's Letters (" + new Date().toLocaleDateString() + ")", showToday, setShowToday)}
          <Today />
        </div>
        <div className="bg-white p-6 rounded shadow-lg">
          {renderDataCard(weekData, "This Week's Letters (" + getWeekRange() + ")", showWeek, setShowWeek)}
          <Week />
        </div>
        <div className="bg-white p-6 rounded shadow-lg">
          {renderDataCard(monthData, "This Month's Letters (" + getMonthName() + ")", showMonth, setShowMonth)}
          <Month />
        </div>
      </div>
    </div>
  );
};

export default Home;